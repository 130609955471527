"use client";

import React, { useState, useEffect } from "react";
import { useActiveAccount } from "thirdweb/react";
import { TokenBalance } from "./utils";
import { goldContractAddress } from "@/lib/tokenDetails";

interface BuyPulseChainButtonProps {
  className?: string;
}

const BuyPulseChainButton: React.FC<BuyPulseChainButtonProps> = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleBuy = (method: 'fiat' | 'crypto') => {
    const url = method === 'fiat'
      ? "https://changenow.io/exchange?from=usd&to=pls&fiatMode=true&amount=1000"
      : "https://changenow.io/exchange?from=btc&to=pls&amount=0.002";
    window.open(url, '_blank');
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block w-full">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full px-4 py-2 text-lg text-white bg-gradient-to-b from-gray-700 to-gray-900 rounded-lg hover:from-gray-600 hover:to-gray-800 transition-all flex items-center justify-between ${className}`}
      >
        <span>Buy PulseChain</span>
        <svg
          className="w-5 h-5 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute top-full left-0 w-full mt-1 bg-gradient-to-b from-gray-700 to-gray-900 rounded-lg shadow-lg overflow-hidden z-10">
          <button
            type="button"
            onClick={() => handleBuy('fiat')}
            className="w-full px-4 py-2 text-white hover:bg-gray-600 text-left transition-colors"
          >
            Buy with FIAT
          </button>
          <button
            type="button"
            onClick={() => handleBuy('crypto')}
            className="w-full px-4 py-2 text-white hover:bg-gray-600 text-left transition-colors"
          >
            Buy with Crypto
          </button>
        </div>
      )}
    </div>
  );
};

const MultiLinkDropdown = ({ links, title }: { links: { href: string; text: string }[]; title: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block w-full">
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-4 py-2 text-lg text-white bg-gradient-to-b from-gray-700 to-gray-900 rounded-lg hover:from-gray-600 hover:to-gray-800 transition-all flex items-center justify-between"
      >
        <b>{title}</b>
        <svg
          className="w-5 h-5 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      {isOpen && (
        <div className="absolute top-full left-0 w-full mt-1 bg-gradient-to-b from-gray-700 to-gray-900 rounded-lg shadow-lg overflow-hidden z-10">
          {links.map((link, index) => (
            <a
              key={index}
              href={link.href}
              className="block w-full px-4 py-2 text-white hover:bg-gray-600 text-left transition-colors"
            >
              <b>{link.text}</b>
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

const SingleLink = ({ href, text }: { href: string; text: string }) => (
  <div className="relative inline-block w-full">
    <a
      href={href}
      className="w-full px-4 py-2 text-lg text-white bg-gradient-to-b from-gray-700 to-gray-900 rounded-lg hover:from-gray-600 hover:to-gray-800 transition-all flex items-center justify-between"
    >
      <b>{text}</b>
    </a>
  </div>
);

export default function Home() {
  const [activeChart, setActiveChart] = useState("v2");
  const [numHolders, setNumHolders] = useState(0);
  const account = useActiveAccount();

  useEffect(() => {
    fetch("https://api.scan.pulsechain.com/api/v2/tokens/0xC41aa9bb67A702Ba3a4cac7beC95635F11cB93D7/holders")
      .then((response) => response.json())
      .then((data) => {
        if (data.items) {
          setNumHolders(data.items.length);
        } else {
          setNumHolders(0);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setNumHolders(0);
      });
  }, []);

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-8">
        <div className="mb-8 bg-gray-800 rounded-lg p-6">
          <div className="flex items-center justify-between mb-4">
            <div className="flex space-x-2">
              <button
                type="button"
                id="v1"
                className={`px-4 py-2 rounded-lg ${
                  activeChart === "v1"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-700 text-gray-300"
                }`}
                onClick={() => setActiveChart("v1")}
              >
                V1
              </button>
              <button
                type="button"
                id="v2"
                className={`px-4 py-2 rounded-lg ${
                  activeChart === "v2"
                    ? "bg-blue-600 text-white"
                    : "bg-gray-700 text-gray-300"
                }`}
                onClick={() => setActiveChart("v2")}
              >
                V2
              </button>
            </div>
            <span className="text-lg font-semibold">Holders: {numHolders}</span>
          </div>

          <div className="w-full h-[600px] bg-gray-900 rounded-lg overflow-hidden">
            <iframe
              className={`w-full h-full ${activeChart === "v1" ? "block" : "hidden"}`}
              src="https://dexscreener.com/pulsechain/0x4DcCa58E00F98e3Fe0C5ef156B6ec56857FcAB77?embed=1&theme=dark"
              title="Dexscreener V1 Chart"
            />
            <iframe
              className={`w-full h-full ${activeChart === "v2" ? "block" : "hidden"}`}
              src="https://dexscreener.com/pulsechain/0x3b3381A85E86b77ac73Db046E745F9D2b232faa5?embed=1&theme=dark"
              title="Dexscreener V2 Chart"
            />
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="p-4 bg-gray-800 rounded-lg">
            <SingleLink
              href="https://pulsex.mypinata.cloud/ipfs/bafybeift2yakeymqmjmonkzlx2zyc4tty7clkwvg37suffn5bncjx4e6xq/#/?outputCurrency=0xC41aa9bb67A702Ba3a4cac7beC95635F11cB93D7"
              text="Swap Gold on PulseX"
            />
          </div>
          <div className="p-4 bg-gray-800 rounded-lg">
            <BuyPulseChainButton />
          </div>
          <div className="p-4 bg-gray-800 rounded-lg">
            <SingleLink
              href="https://gorealdefi.com/"
              text="Go Real Defi"
            />
          </div>
          <div className="p-4 bg-gray-800 rounded-lg">
            <SingleLink
              href="https://otter.pulsechain.com/address/0xC41aa9bb67A702Ba3a4cac7beC95635F11cB93D7/"
              text="Otter"
            />
          </div>
          <div className="p-4 bg-gray-800 rounded-lg">
            <SingleLink
              href="https://beacon.pulsechain.com/address/c41aa9bb67a702ba3a4cac7bec95635f11cb93d7"
              text="Beacon"
            />
          </div>
          <div className="p-4 bg-gray-800 rounded-lg">
            <SingleLink
              href="https://v2.onlymoons.io/#/locker/pulse/4167"
              text="Proof of liquidity lock"
            />
          </div>
          <div className="p-4 bg-gray-800 rounded-lg">
            <MultiLinkDropdown
              title="PulseX Links"
              links={[
                { href: "https://v2-app.pulsex.com/info/token/0xc41aa9bb67a702ba3a4cac7bec95635f11cb93d7", text: "Pair Info" },
                { href: "https://v2-app.pulsex.com/info/token/0xc41aa9bb67a702ba3a4cac7bec95635f11cb93d7", text: "PulseX: V2" },
                { href: "https://app.pulsex.com/info/token/0xc41aa9bb67a702ba3a4cac7bec95635f11cb93d7", text: "PulseX: V1" }
              ]}
            />
          </div>
          <div className="p-4 bg-gray-800 rounded-lg">
            <MultiLinkDropdown
              title="CoinMarketCap Links"
              links={[
                { href: "https://coinmarketcap.com/currencies/gold-token/", text: "Coin MarketCap" },
                { href: "https://coinmarketcap.com/dexscan/pulsechain/0x3b3381a85e86b77ac73db046e745f9d2b232faa5/", text: "Gold/wpls V2" },
                { href: "https://coinmarketcap.com/dexscan/pulsechain/0x4dcca58e00f98e3fe0c5ef156b6ec56857fcab77/", text: "Gold/wpls V1" }
              ]}
            />
          </div>
          <div className="p-4 bg-gray-800 rounded-lg">
            <MultiLinkDropdown
              title="CoinGecko Links"
              links={[
                { href: "https://www.geckoterminal.com/pulsechain/pools/0x3b3381a85e86b77ac73db046e745f9d2b232faa5", text: "CoinGecko" },
                { href: "https://www.geckoterminal.com/pulsechain/pools/0x3b3381a85e86b77ac73db046e745f9d2b232faa5", text: "V2" },
                { href: "https://www.geckoterminal.com/pulsechain/pools/0x4dcca58e00f98e3fe0c5ef156b6ec56857fcab77/", text: "V1" }
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
